<template>
	<div style="overflow-y: auto;height:100vh;">
		<el-row class="status-bar">
			<el-col :xs="24" :sm="12" :md="5" :span="6">订单号：{{ orderDetail.orderNumber }}</el-col>
			<el-col :xs="24" :sm="12" :md="4" :span="6">支付状态： {{ orderDetail.isPayed === 1 ? '已支付' : '未支付' }}</el-col>
			<el-col :xs="24" :sm="12" :md="4" :span="6">
				订单状态：
				{{
					orderDetail.status === 1
						? '待付款'
						: orderDetail.status === 2
						? '待发货'
						: orderDetail.status === 3
						? '待收货'
						: orderDetail.status === 4
						? '待评价'
						: orderDetail.status === 5
						? '成功'
						: orderDetail.status === 6
						? '失效'
						: ''
				}}
				<!-- {label:"待付款",value:1},{label:"待发货",value:2},{label:"待收货",value:3},{label:"待评价",value:4},{label:"成功",value:5},{label:"失败",value:6} -->
			</el-col>
			<el-col :xs="24" :sm="12" :md="4" :span="6">
				退款状态：
				{{
					orderDetail.refundSts === 0
						? '未处理'
						: orderDetail.refundSts === 1
						? '处理中'
						: orderDetail.refundSts === 2
						? '处理完成'
						: orderDetail.refundSts === 3
						? '已拒绝'
						: orderDetail.refundSts === 4
						? '售后过期'
						: ''
				}}
				<!-- {label:"未处理",value:0},{label:"在处理",value:1},{label:"处理完成",value:2},{label:"拒绝",value:3},{label:"售后过期",value:4}] -->
			</el-col>
		</el-row>
		<div class="current-box">
			<el-container class="current-status">
				<el-aside class="aside-box" width="360px">
					<h3 v-if="orderDetail.status===1">买家下单</h3>
					<h3 v-if="orderDetail.status===2">买家已付款，等待商家发货</h3>
					<h3 v-if="orderDetail.status===3">商家已发货，等待买家签收</h3>
					<h3 v-if="orderDetail.status===4">买家已收货，等待评价</h3>
					<h3 v-if="orderDetail.status===5">买家已评价</h3>
					<h3 v-if="orderDetail.status===6">订单已失效</h3>
				</el-aside>
				<el-main class="main-box">
					<div class="main-line-step">
						<span class="number active">1</span>
						<span class="line active"></span>
						<span :class="['number',orderDetail.status!==1?'active':'']">2</span>
						<span :class="['line',orderDetail.status!==1?'active':'']"></span>
						<span :class="['number',orderDetail.status!==1 && orderDetail.status!==2?'active':'']">3</span>
						<span :class="['line',orderDetail.status!==1 && orderDetail.status!==2?'active':'']"></span>
						<span :class="['number',orderDetail.status!==1 && orderDetail.status!==2 && orderDetail.status!==3?'active':'']">4</span>
					</div>
					<div class="main-line-step" style="margin-top: 10px;">
						<h4 class="active">
							<div>买家下单</div>
							<div></div>
						</h4>
						<h4 :class="[orderDetail.status!==1?'active':'']">
							<div>买家付款</div>
							<div></div>
						</h4>
						<h4 :class="[orderDetail.status!==1 && orderDetail.status!==2?'active':'']">
							<div>等待商家确认</div>
							<div></div>
						</h4>
						<h4 :class="[orderDetail.status!==1 && orderDetail.status!==2 && orderDetail.status!==3?'active':'']">
							交易完成
						</h4>
					</div>
				</el-main>
			</el-container>
			<div class="order-remark" style="border-top:1px solid #ededed;">
				备注:{{orderDetail.remarks?orderDetail.remarks:''}}
			</div>
		</div>
		<el-container class="user-info">
			<el-header class="info-header">
				<el-row>
					<el-col :span="8">收货人信息</el-col>
					<el-col :span="6">配送信息</el-col>
					<el-col :span="6">支付信息</el-col>
					<el-col :span="4">买家信息</el-col>
				</el-row>
			</el-header>
			<el-main class="info-main">
				<el-row>
					<el-col :span="8" v-if="orderDetail.userAddrOrder">
						<h4><span class="info-label">姓名：</span>{{orderDetail.userAddrOrder.receiver}}</h4>
						<h4><span class="info-label">电话：</span>{{orderDetail.userAddrOrder.mobile}}</h4>
						<h4><span class="info-label">地址：</span>{{orderDetail.userAddrOrder.province+orderDetail.userAddrOrder.city+orderDetail.userAddrOrder.area}}</h4>
						<h4><span class="info-label">详细地址：</span>{{orderDetail.userAddrOrder.addr}}</h4>
					</el-col>
					<el-col :span="6">
						<h4><span class="info-label">物流单号：</span>{{orderDetail.dvyFlowId || '_'}}</h4>
						<h4><span class="info-label">发货时间：</span>{{orderDetail.dvyTime || '_'}}</h4>
						<h4><span class="info-label">订单运费：</span>{{orderDetail.freightAmount || 0}}</h4>
					</el-col>
					<el-col :span="6">
						<h4><span class="info-label">支付方式：</span>{{ orderDetail.payType === 1 ? '微信支付' : orderDetail.payType === 2 ? '支付宝支付' : orderDetail.payType === 3 ? '余额支付' : '' }}</h4>
						<h4><span class="info-label">付款金额：</span>{{orderDetail.actualTotal || 0}}</h4>
						<h4><span class="info-label">付款时间：</span>{{orderDetail.payTime || '_'}}</h4>
					</el-col>
					<el-col :span="4">
						<h4><span class="info-label">买家电话：</span>{{orderDetail.phone || '_'}}</h4>
						<h4><span class="info-label">下单时间：</span>{{orderDetail.createTime || '_'}}</h4>
					</el-col>
				</el-row>
			</el-main>
		</el-container>
		<el-container class="user-info">
			<el-header class="info-header">
				<el-row>
					<el-col :span="7">商品信息</el-col>
					<el-col :span="3" class="item-center">规格/sku</el-col>
					<el-col :span="4" class="item-center">单价</el-col>
					<el-col :span="4" class="item-center">数量</el-col>
					<!-- <el-col :span="3" class="item-center">优惠信息</el-col> -->
					<el-col :span="4" class="item-center">小计</el-col>
					<el-col :span="2" class="item-center">运费</el-col>
					<!-- <el-col :span="4" class="item-center">售后</el-col> -->
				</el-row>
			</el-header>
			<el-main class="info-main">
				<el-row class="info-item" v-for="(item,index) in orderDetail.orderItems" :key="index">
					<el-col :span="7">
						<div class="product-img">
							<el-image 
								:z-index="60000"
							    style="width: 100px; height: 100px"
							    :src="item.pic" 
							    :preview-src-list="[item.pic]">
							</el-image>
							<span>{{item.prodName}}</span>
						</div>
					</el-col>
					<el-col :span="3">
						<div class="item-center" v-if="item.skuName">{{item.skuName.split(',')[1]}}</div>
						<div class="item-center" v-else>{{item.prodName}}</div>
					</el-col>
					<el-col :span="4">
						<div class="item-center">{{item.prodCount}}</div>
					</el-col>
					<el-col :span="4">
						<div class="item-center">￥{{item.productTotalAmount}}</div>
					</el-col>
					<el-col :span="4">
						<div class="item-center">￥{{item.productTotalAmount}}</div>
					</el-col>
					<el-col :span="2">
						<div class="item-center">_</div>
					</el-col>
				</el-row>
				<div class="order-remark" style="padding-top: 15px;">
					<el-col :span="4">
						订单总价:￥{{orderDetail.total || 0}}
					</el-col>
					<el-col :span="4">
						优惠金额:￥{{orderDetail.reduceAmount || 0}}
					</el-col>
					<el-col :span="4">
						实际支付金额:￥{{orderDetail.actualTotal || 0}}
					</el-col>
					<el-col :span="4">
						退款状态:{{orderDetail.refundSts==1?'处理中':orderDetail.refundSts==2?'处理完成':'_'}}
					</el-col>
					<el-col :span="4">
						关单状态:{{orderDetail.closeType==1?'超时未支付':orderDetail.closeType==2?'退款关闭':orderDetail.closeType==4?'买家取消':orderDetail.closeType==15?'已通过货到付款交易':'_'}}
					</el-col>
				</div>
			</el-main>
		</el-container>
	</div>
</template>

<script>
export default {
	props: {
		selectdata: {
			type: Object,
			default: () => {}
		}
	},
	data() {
		return {
			orderDetail: {}
		};
	},
	created() {
		this.getOrderDetail();
	},
	methods: {
		async getOrderDetail() {
			const res = await this.$axios('get', `/sys/order/orderInfo?orderNumber=${this.selectdata.orderNumber}`, {}, true);
			this.orderDetail = res;
		}
	}
};
</script>

<style lang="scss" scoped>
.status-bar {
	border-top: 1px solid #ededed;
	padding-top: 15px;
}
.current-box{
	border: 1px solid #ededed;
	margin-top: 15px;
	.current-status {
		height: 100px;
		box-sizing: border-box;
		.aside-box {
			border-right: 1px solid #ededed;
			height: 100px;
			box-sizing: border-box;
			background: #fff;
			padding: 0 20px;
			@include flex-style(row,wrap,flex-start,center);
		}
		.main-box {
			height: 100px;
			box-sizing: border-box;
			.main-line-step{
				@include flex-style(row,nowrap,flex-start,center);
				.number{
					border: 1px solid #204274;
					width: 20px;
					height: 20px;
					border-radius: 50%;
					text-align: center;
					color: #204274;
					&.active{
						color: royalblue;
						border-color: royalblue;
					}
				}
				.line{
					width: 20%;
					border-bottom: 1px solid #204274;
					&.active{
						border-color: royalblue;
					}
				}
				h4{
					width: calc(20% + 22px);
					&.active{
						color: royalblue;
					}
				}
			}
		}
	}
	.order-remark{
		min-height: 30px;
		padding: 2px 20px;
	}
}
.user-info{
	border: 1px solid #ededed;
	margin-top: 30px;
	.info-header{
		background: #f4f4f4;
		border-bottom: 1px solid #ededed;
		height: 42px !important;
		line-height: 42px;
		.item-center{
			text-align: center;
		}
	}
	.info-main{
		.info-label{
			min-width: 70px;
			display: inline-block;
			text-align: right;
		}
		.info-item{
			border-bottom: 1px solid #ededed;
			padding: 10px 0;
			&:last-child{
				border-bottom: 0;
			}
			.item-center{
				width: 100%;
				height: 100px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			.product-img{
				@include flex-style(row,nowrap,flex-start,flex-start);
				>span{
					flex: 1;
					padding: 10px;
				}
			}
		}
	}
}
</style>
